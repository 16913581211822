import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RestService } from '@abp/ng.core';

@Injectable({
	providedIn: 'root',
})
export class SettingsFilesService {
	http!: HttpClient;
	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
	};
	url!: string;

	//#region Ctor
	constructor(http: HttpClient, private restService: RestService) {
		this.http = http;

		if (!environment.apis.default.url) throw new Error('Base URL cannot be null');

		this.url = environment.apis.default.url;
	}
	//#endregion

	public updateMedia(formData: FormData): Observable<any> {
		return this.http.post(`${this.url}/api/app/media-settings/upload-media`, formData, {
			reportProgress: true,
			observe: 'events',
		});
	}
}
