<nav
  class="navbar navbar-expand-lg navbar-dark bg-dark shadow-sm flex-column flex-md-row mb-4"
  id="main-navbar"
  style="min-height: 4rem"
>
  <div class="container">
    <abp-logo *abpReplaceableTemplate="{ componentKey: service.logoComponentKey }"></abp-logo>
    <button
      class="navbar-toggler"
      type="button"
      [attr.aria-expanded]="!service.isCollapsed"
      (click)="service.isCollapsed = !service.isCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="navbar-collapse"
      [class.overflow-hidden]="service.smallScreen"
      id="main-navbar-collapse"
    >
      <ng-container *ngTemplateOutlet="!service.smallScreen ? navigations : null"></ng-container>

      <div
        *ngIf="service.smallScreen"
        [@collapseWithMargin]="service.isCollapsed ? 'collapsed' : 'expanded'"
      >
        <ng-container *ngTemplateOutlet="navigations"></ng-container>
      </div>

      <ng-template #navigations>
        <abp-routes
          *abpReplaceableTemplate="{
            componentKey: service.routesComponentKey,
            inputs: {
              smallScreen: { value: service.smallScreen }
            }
          }"
          class="mx-auto"
          [smallScreen]="service.smallScreen"
        ></abp-routes>

        <abp-nav-items
          *abpReplaceableTemplate="{
            componentKey: service.navItemsComponentKey
          }"
        ></abp-nav-items>
      </ng-template>
    </div>
  </div>
</nav>

<!-- [@slideFromBottom]="outlet.isActivated && outlet.activatedRoute?.routeConfig?.path" TODO: throws ExpressionChangedAfterItHasBeenCheck when animation is active. It should be fixed -->
<div class="container">
  <abp-page-alert-container></abp-page-alert-container>
  <abp-auth-wrapper
    *abpReplaceableTemplate="{
      componentKey: authWrapperKey
    }"
  >
    <router-outlet #outlet="outlet"></router-outlet>
  </abp-auth-wrapper>
</div>
