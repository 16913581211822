import { LocalizationParam, LocalizationService } from '@abp/ng.core';
import { Toaster } from '@abp/ng.theme.shared';
import { Inject, Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { MessageSeverity } from '../shared/app-constants';

@Injectable({
  providedIn: 'root',
})
export class PrimengToasterService implements Toaster.Service {
  private lastId = 0;

  private nextId(): number {
    return (this.lastId = this.lastId++);
  }

  private mapAbpSeverityToPrimeSeverity(severity: Toaster.Severity): MessageSeverity {
    // 'neutral' | 'success' | 'info' | 'warning' | 'error'
    switch (severity) {
      case 'success':
        return 'success';
      case 'info':
        return 'info';
      case 'warning':
        return 'warn';
      case 'error':
        return 'error';
      default:
        return null;
    }
  }

  private messageObserver(message: Message | Message[]): void {
  }

  //#region Ctor
  constructor(
    @Inject(MessageService) private messageService: MessageService,
    private localizationService: LocalizationService
  ) {
    // this.messageService.messageObserver.subscribe((message: Message | Message[]) => this.messageObserver(message));
  }
  //#endregion

  show(
    message: LocalizationParam,
    title: LocalizationParam,
    severity: Toaster.Severity,
    options: Partial<Toaster.ToastOptions>
  ): number {
    const translatedMessage = this.localizationService.instant(message);
    const translatedTitle = this.localizationService.instant(title);

    const msg = {
      summary: translatedMessage,
      detail: translatedTitle,
      severity: this.mapAbpSeverityToPrimeSeverity(severity),
      closable: options?.closable,
      sticky: options?.sticky,
      key: options?.containerKey,
      id: this.nextId(),
      life: options?.life,
    } as Message;

    this.messageService.add(msg);

    return msg.id;
  }

  remove(id: number): void {
    this.messageService.clear(id.toString());
  }

  clear(containerKey?: string): void {
    this.messageService.clear(containerKey);
  }

  info(
    message: LocalizationParam,
    title?: LocalizationParam,
    options?: Partial<Toaster.ToastOptions>
  ): number {
    return this.show(message, title, 'info', options);
  }

  success(
    message: LocalizationParam,
    title?: LocalizationParam,
    options?: Partial<Toaster.ToastOptions>
  ): number {
    return this.show(message, title, 'success', options);
  }

  warn(
    message: LocalizationParam,
    title?: LocalizationParam,
    options?: Partial<Toaster.ToastOptions>
  ): number {
    return this.show(message, title, 'warning', options);
  }

  error(
    message: LocalizationParam,
    title?: LocalizationParam,
    options?: Partial<Toaster.ToastOptions>
  ): number {
    return this.show(message, title, 'error', options);
  }
}
