<div class="row">
  <div class="mx-auto col col-md-5">
    <ng-container *ngIf="(service.isMultiTenancyEnabled$ | async) && service.isTenantBoxVisible">
      <abp-tenant-box
        *abpReplaceableTemplate="{ componentKey: service.tenantBoxKey }"
      ></abp-tenant-box>
    </ng-container>

    <div class="abp-account-container">
      <div
        *ngIf="service.enableLocalLogin$ | async; else disableLocalLoginTemplate"
        class="card mt-3 shadow-sm rounded"
      >
        <div class="card-body p-5">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #disableLocalLoginTemplate>
  <div class="alert alert-warning">
    <strong>{{ 'AbpAccount::InvalidLoginRequest' | abpLocalization }}</strong>
    {{ 'AbpAccount::ThereAreNoLoginSchemesConfiguredForThisClient' | abpLocalization }}
  </div>
</ng-template>
