import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
		canActivate: [AuthGuard],
		data: { roles: ['any'] },
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
		canActivate: [AuthGuard],
		data: { roles: ['any'] },
	},
	{
		path: 'charts',
		loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'Admin - Limited'] },
	},
	{
		path: 'redirect',
		pathMatch: 'full',
		loadChildren: () => import('./redirect/redirect.module').then(m => m.RedirectModule),
		data: { roles: ['any'] },
	},
	{
		path: 'account',
		loadChildren: () =>
			import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'gdpr',
		loadChildren: () => import('@volo/abp.ng.gdpr').then(m => m.GdprModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'identity',
		loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'language-management',
		loadChildren: () =>
			import('@volo/abp.ng.language-management').then(m =>
				m.LanguageManagementModule.forLazy()
			),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'saas',
		loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'audit-logs',
		loadChildren: () =>
			import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'openiddict',
		loadChildren: () =>
			import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'text-template-management',
		loadChildren: () =>
			import('@volo/abp.ng.text-template-management').then(m =>
				m.TextTemplateManagementModule.forLazy()
			),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'setting-management',
		loadChildren: () =>
			import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'gdpr-cookie-consent',
		loadChildren: () =>
			import('./gdpr-cookie-consent/gdpr-cookie-consent.module').then(
				m => m.GdprCookieConsentModule
			),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'partners',
		loadChildren: () => import('./partners/partner/partner.module').then(m => m.PartnerModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'Admin - Limited'] },
	},
	{
		path: 'approvals',
		loadChildren: () =>
			import('./approvals/approval/approval.module').then(m => m.ApprovalModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'Admin - Limited'] },
	},
	{
		path: 'reports',
		loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'Partner', 'Admin - Limited'] },
	},
	{
		path: 'leads',
		loadChildren: () => import('./leads/lead/lead.module').then(m => m.LeadModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'Admin - Limited'] },
	},
	{
		path: 'support',
		loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student', 'Partner'] },
	},
	{
		path: 'media-files',
		loadChildren: () =>
			import('./media-files/media-file/media-file.module').then(m => m.MediaFileModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'all-students',
		loadChildren: () =>
			import('./partner-students/partner-students.module').then(m => m.PartnerStudentsModule),
		canActivate: [AuthGuard],
		data: { roles: ['Partner'] },
	},
	{
		path: 'my-profile',
		loadChildren: () =>
			import('./partner-profile/partner-profile.module').then(m => m.PartnerProfileModule),
		canActivate: [AuthGuard],
		data: { roles: ['Partner'] },
	},
	{
		path: 'settings',
		loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'Marketing', 'Admin - Limited'] },
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'Admin - Limited'] },
	},
	{
		path: 'members',
		loadChildren: () => import('./members/members.module').then(m => m.MembersModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'Admin - Limited'] },
	},
	{
		path: 'content',
		loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'registrations',
		loadChildren: () =>
			import('./registrations/registrations.module').then(m => m.RegistrationsModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin', 'Partner', 'Admin - Limited'] },
	},
	{
		path: 'codes',
		loadChildren: () => import('./codes/codes.module').then(m => m.CodesModule),
		canActivate: [AuthGuard],
		data: { roles: ['Partner'] },
	},
	{
		path: 'study-guides',
		loadChildren: () =>
			import('./student-study-guides/student-study-guides.module').then(
				m => m.StudentStudyGuidesModule
			),
		data: { roles: ['Student'] },
	},
	{
		path: 'student-certifications',
		loadChildren: () =>
			import('./student-certifications/student-certifications.module').then(
				m => m.StudentCertificationsModule
			),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'ces',
		loadChildren: () => import('./ces/ces.module').then(m => m.CesModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'student-contact-us',
		loadChildren: () =>
			import('./student-contact-us/student-contact-us.module').then(
				m => m.StudentContactUsModule
			),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'student-account',
		loadChildren: () =>
			import('./student-account/student-account.module').then(m => m.StudentAccountModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'ces',
		loadChildren: () => import('./ces/ces.module').then(m => m.CesModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'student-eligibility',
		loadChildren: () =>
			import('./student-eligibility/student-eligibility.module').then(
				m => m.StudentEligibilityModule
			),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'course-content',
		loadChildren: () =>
			import('./student-course-content/student-course-content.module').then(
				m => m.StudentCourseContentModule
			),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	// {
	// 	path: 'cms',
	// 	loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule),
	// 	canActivate: [AuthGuard],
	// 	data: { roles: ['admin'] },
	// },
	{
		path: '**',
		redirectTo: '/',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
