import { differentLocales } from '@abp/ng.core';
import { isDevMode } from '@angular/core';
let localeMap = {};
function registerLocale({
  cultureNameLocaleFileMap = {},
  errorHandlerFn = defaultLocalErrorHandlerFn
} = {}) {
  return locale => {
    localeMap = {
      ...differentLocales,
      ...cultureNameLocaleFileMap
    };
    const localePath = `/locales/${localeMap[locale] || locale}`;
    return new Promise((resolve, reject) => {
      return import( /* webpackMode: "lazy-once" */
      /* webpackChunkName: "locales"*/
      /* webpackInclude: /[/\\](ar|cs|en|en-GB|es|de|fi|fr|hi|hu|is|it|pt|tr|ru|ro|sk|sl|zh-Hans|zh-Hant)\.(mjs|js)$/ */
      /* webpackExclude: /[/\\]global|extra/ */
      `@angular/common${localePath}`).then(val => {
        let module = val;
        while (module.default) {
          module = module.default;
        }
        resolve({
          default: module
        });
      }).catch(error => {
        errorHandlerFn({
          resolve,
          reject,
          error,
          locale
        });
      });
    });
  };
}
const extraLocales = {};
function storeLocaleData(data, localeId) {
  extraLocales[localeId] = data;
}
async function defaultLocalErrorHandlerFn({
  locale,
  resolve
}) {
  if (extraLocales[locale]) {
    resolve({
      default: extraLocales[localeMap[locale] || locale]
    });
    return;
  }
  if (isDevMode()) {
    console.error(`Cannot find the ${locale} locale file. You can check how can add new culture at https://docs.abp.io/en/abp/latest/UI/Angular/Localization#adding-a-new-culture`);
  }
  resolve();
}

/**
 * Generated bundle index. Do not edit.
 */

export { defaultLocalErrorHandlerFn, registerLocale, storeLocaleData };
