<div
	class="offcanvas-xl offcanvas-start offcanvas-expand-xl h-100 d-flex flex-column border-end"
	tabindex="-1"
	id="offcanvasSideBar"
	style="width: 240px">
	<div class="offcanvas-header d-flex">
		<a
			role="button"
			routerLink="/"
			class="offcanvas-title ms-4 my-3 dashboard-title fw-medium text-decoration-none"
			style="font-size: 18px">
			Dashboard
		</a>
	</div>

	<div class="offcanvas-body h-100" style="overflow-y: auto">
		<div class="w-100">
			<abp-routes
				*abpReplaceableTemplate="{
					componentKey: service.routesComponentKey,
					inputs: {
						smallScreen: { value: service.smallScreen }
					}
				}"
				[smallScreen]="service.smallScreen"></abp-routes>
		</div>
	</div>
</div>
